export function Footer() {
  return (
    <footer className="base-px bg-bnft-gray text-[0.5rem] md:text-xs leading-6 pt-8">
      <div className="py-8 border-t border-bnft-dark-gray justify-between">
        <div className="mb-6">
          <p>Copyright © Since 2022 by Banner NFTs</p>
          <p>
            If you decide to copy some idea or anything from this humble
            website, then please transfer 1Ξ or whatever you can afford to
            0x3ee7171FcBEe0FF94889F08b46f1Ca933c142b6E otherwise I will have to
            hunt you down and force you to give me your private keys.
          </p>
        </div>
      </div>
    </footer>
  );
}
