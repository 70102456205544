import { TARGET_CHAIN_ID } from "../types/consts";

export function NotConnected({ isWrongNetwork }: { isWrongNetwork: boolean }) {
  return (
    <div id="not-connected" className="flex h-screen text-center flex-col">
      {/* TODO: externalize strings */}

      <div className="text-5xl bold mt-52">
        Hey, you gotta connect your wallet to use this 😔
      </div>
      {isWrongNetwork && (
        <div className="mt-14 text-xl">
          🕸️ And make sure you are on the correct network: Ethereum Mainnet -
          chain id {TARGET_CHAIN_ID} ⛓️
        </div>
      )}
    </div>
  );
}
