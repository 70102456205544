import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Wallet } from "./Wallet";

import { AppContext } from "../contexts/app-context";
import { useTranslation } from "react-i18next";
import { LOGO } from "../types/consts";

const navLinks = ["whats-this", "collection", "mint", "my-nfts"];

export function Header() {
  const { t } = useTranslation();
  const { account } = useContext(AppContext);
  let location = useLocation();

  const [selectedNavLink, setSelectedNavLink] = useState(navLinks[0]);

  useEffect(() => {
    let parts = location.pathname.split("/");
    setSelectedNavLink(parts[1]);
  }, [location.pathname]);

  return (
    <div className="base-px sticky top-0 z-50 w-full flex-none bg-bnft-gray">
      <div className="mx-auto">
        <div className="py-4 border-0 mx-0">
          <div className="relative block md:flex items-center">
            <Link
              to="/"
              className="md:mr-3 flex justify-center flex-none w-auto overflow-hidden"
            >
              <img className="h-8 w-16" src={LOGO} alt="Banner NFTs" />
            </Link>

            <div className="space-x-4 py-4 text-center md:py-1 md:px-3 md:ml-10 items-center">
              <Link
                key={navLinks[0]}
                to="/whats-this"
                className={
                  navLinks[0] === selectedNavLink ? "link-current" : "link"
                }
              >
                {t("header:whats-this")}
              </Link>
              <Link
                key={navLinks[1]}
                to="/collection"
                className={
                  navLinks[1] === selectedNavLink ? "link-current" : "link"
                }
              >
                {t("header:collection")}
              </Link>
              <Link
                key={navLinks[2]}
                to="/mint"
                className={
                  navLinks[2] === selectedNavLink ? "link-current" : "link"
                }
              >
                {t("header:mint")}
              </Link>
            </div>

            <nav className="ml-auto flex space-x-8 justify-center">
              {account != null ? (
                <Link
                  key={navLinks[3]}
                  to="/my-nfts"
                  className={
                    navLinks[3] === selectedNavLink ? "link-current" : "link"
                  }
                >
                  {t("header:my-nfts")}
                </Link>
              ) : (
                <></>
              )}
              <Wallet />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
