import { utils } from "ethers";

// local
// const TARGET_CHAIN_ID = 31337;
// const ADDRESS_BANNER_NFT_CONTRACT =
//   "0xC9449a900dAd9a9860EEd2915b64eAfEE7a2171f";

// mainnet
const TARGET_CHAIN_ID = 1;
const ADDRESS_BANNER_NFT_CONTRACT =
  "0xC9449a900dAd9a9860EEd2915b64eAfEE7a2171f";

const MAX_TOKENS_TO_FETCH = 88;

const MAX_TEXT_LENGTH = 20;

const UPDATE_TOKEN_COST_VALUE = "0.000008";
const UPDATE_TOKEN_COST_UNIT = "ether";
const UPDATE_TOKEN_COST = utils.parseUnits(
  UPDATE_TOKEN_COST_VALUE,
  UPDATE_TOKEN_COST_UNIT
);

const LOGO =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHByZXNlcnZlQXNwZWN0UmF0aW89J3hNaW5ZTWluIG1lZXQnIHZpZXdCb3g9JzAgMCA3MDAgMzUwJz48c3R5bGU+LmJhc2UgeyBmaWxsOiAjOTVjZDQxOyBmb250LWZhbWlseTogc2VyaWY7IHRleHQtc2hhZG93OiAxcHggMXB4ICM2NjYsIDNweCAzcHggIzAwMDsgZm9udC1zaXplOiAxNXJlbTsgfTwvc3R5bGU+PHJlY3Qgd2lkdGg9JzEwMCUnIGhlaWdodD0nMTAwJScgZmlsbD0nI2VhNWMyYicvPjx0ZXh0IHg9JzUwJScgeT0nNTAlJyBjbGFzcz0nYmFzZScgZG9taW5hbnQtYmFzZWxpbmU9J21pZGRsZScgdGV4dC1hbmNob3I9J21pZGRsZSc+QjwvdGV4dD48L3N2Zz4=";

export {
  LOGO,
  MAX_TEXT_LENGTH,
  UPDATE_TOKEN_COST,
  UPDATE_TOKEN_COST_VALUE,
  UPDATE_TOKEN_COST_UNIT,
  MAX_TOKENS_TO_FETCH,
  TARGET_CHAIN_ID,
  ADDRESS_BANNER_NFT_CONTRACT,
};
