import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "../styles/common.css";
import "../styles/landing.css";

function Landing() {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen flex bg-bnft-gray">
      <div className="m-auto">
        <div className="text-center px-12 py-8 md:px-48 default-letterboard">
          <p>BANNER</p>
          <p>NFTs</p>
          <p className="subtitle text-xs md:text-sm mt-8">
            {t("landing:subtitle")}
          </p>
        </div>
        <div className="mt-5 grid grid-cols-3">
          <div className="text-left">
            <Link to="/whats-this" className="link-with-bg">
              {t("landing:learn")}
            </Link>
          </div>
          <div className="text-center">
            <Link to="/collection" className="link-with-bg">
              {t("landing:explore")}
            </Link>
          </div>
          <div className="text-right">
            <Link to="/my-nfts" className="link-with-bg">
              {t("landing:member")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
