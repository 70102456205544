import React from "react";
import ReactDOM from "react-dom";
import { Config, DAppProvider, Hardhat, Localhost, Mainnet, Kovan, Rinkeby, ChainId } from "@usedapp/core";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./i18n";
// import reportWebVitals from "./reportWebVitals";
import { ScrollToTop } from "./components/ScrollToTop";
import App from "./pages/App";
import Landing from "./pages/Landing";

import "./styles/index.css";
import "./styles/common.css";

const config: Config = {
  networks: [Mainnet],
};

const LazyWhatsThis = React.lazy(() => import("./pages/WhatsThis"));
const LazyCollection = React.lazy(() => import("./pages/Collection"));
const LazyMint = React.lazy(() => import("./pages/Mint"));
const LazyViewNFT = React.lazy(() => import("./pages/ViewNFT"));
const LazyEditNFT = React.lazy(() => import("./pages/EditNFT"));
const LazyMyNFTs = React.lazy(() => import("./pages/MyNFTs"));
const LazyNotFound = React.lazy(() => import("./pages/NotFound"));

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <React.StrictMode>
      <DAppProvider config={config}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/" element={<App />}>
            <Route
              path="whats-this"
              element={
                <React.Suspense fallback={<>...</>}>
                  <LazyWhatsThis />
                </React.Suspense>
              }
            />
            <Route
              path="collection"
              element={
                <React.Suspense fallback={<>...</>}>
                  <LazyCollection />
                </React.Suspense>
              }
            />
            <Route
              path="mint"
              element={
                <React.Suspense fallback={<>...</>}>
                  <LazyMint />
                </React.Suspense>
              }
            />
            <Route
              path="collection/:tokenId"
              element={
                <React.Suspense fallback={<>...</>}>
                  <LazyViewNFT />
                </React.Suspense>
              }
            />
            <Route
              path="collection/:tokenId/edit"
              element={
                <React.Suspense fallback={<>...</>}>
                  <LazyEditNFT />
                </React.Suspense>
              }
            />
            <Route
              path="my-nfts"
              element={
                <React.Suspense fallback={<>...</>}>
                  <LazyMyNFTs />
                </React.Suspense>
              }
            />
            <Route
              path="*"
              element={
                <React.Suspense fallback={<>...</>}>
                  <LazyNotFound />
                </React.Suspense>
              }
            />{" "}
          </Route>
        </Routes>
      </DAppProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
