import { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useEthers } from "@usedapp/core";

import { AppContext } from "../contexts/app-context";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { NotConnected } from "../components/NotConnected";
import { TARGET_CHAIN_ID } from "../types/consts";

function App() {
  const [account, setAccount] = useState(null);
  const { library } = useEthers();

  const [isWrongNetwork, setIsWrongNetwork] = useState<boolean>(true);

  const getChainId = useCallback(async () => {
    if (library) {
      let network = await library.getNetwork();

      if (network.chainId === TARGET_CHAIN_ID) {
        return setIsWrongNetwork(false);
      } else {
        return setIsWrongNetwork(true);
      }
    } else {
      setIsWrongNetwork(true);
    }
  }, [library]);

  const isGatedRoute = (): boolean => {
    const gatedRoutes = ["mint", "my-nfts", "edit"];

    if (gatedRoutes.some((gr) => location.pathname.includes(gr))) return true;

    return false;
  };

  useEffect(() => {
    getChainId();
  }, [getChainId]);

  const location = useLocation();

  return (
    <AppContext.Provider value={{ account, setAccount }}>
      <Header />
      <div className="min-h-screen place-content-center base-px pt-10 bg-bnft-gray">
        {(!account || isWrongNetwork) && isGatedRoute() ? (
          <NotConnected isWrongNetwork={isWrongNetwork} />
        ) : (
          <Outlet />
        )}
      </div>
      <Footer />
    </AppContext.Provider>
  );
}

export default App;
