import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEthers } from "@usedapp/core";
import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink";
import Web3Modal from "web3modal";

import { AppContext } from "../contexts/app-context";

export function Wallet() {
  const { t } = useTranslation();
  const { account, activate, deactivate } = useEthers();
  const [isConnected, setIsConnected] = useState(false);
  const { setAccount } = useContext(AppContext);

  useEffect(() => {
    // triggered by side effects from `onWalletClick`
    if (account != null) {
      setIsConnected(true);
      setAccount(account);
    } else {
      setIsConnected(false);
      setAccount(null);
    }
  }, [account, setAccount]);

  async function onWalletClick() {
    if (account == null) {
      const providerOptions = {
        injected: {
          display: {
            name: "Metamask",
            description: "Connect with the provider in your Browser",
          },
          package: null,
        },
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            bridge: "https://bridge.walletconnect.org",
            infuraId: "14a0951f47e646c1b241aa533e150219",
          },
        },
        walletlink: {
          package: WalletLink,
          options: {
            appName: "Banner NFTs",
            infuraId: "14a0951f47e646c1b241aa533e150219",
          },
        },
      };

      const web3Modal = new Web3Modal({
        providerOptions,
      });

      try {
        const provider = await web3Modal.connect();
        await activate(provider);
      } catch (error: any) {
        // TODO: show a better error message
        console.log("error connecting", error.message);
      }
    } else {
      deactivate();
    }
  }

  return (
    <span>
      <button
        type="button"
        className={
          isConnected ? "btn-cancel bg-red-700" : "btn-action bg-blue-600"
        }
        onClick={onWalletClick}
      >
        {isConnected ? t("header:disconnect") : t("header:connect")}
      </button>
    </span>
  );
}
