import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import headerEN from "./locales/en/header.json";
import footerEN from "./locales/en/footer.json";
import landingEN from "./locales/en/landing.json";
import whatsThisEN from "./locales/en/whats-this.json";
import collectionEN from "./locales/en/collection.json";
import mintEN from "./locales/en/mint.json";
import myNftsEN from "./locales/en/my-nfts.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        header: headerEN,
        footer: footerEN,
        landing: landingEN,
        whatsThis: whatsThisEN,
        collection: collectionEN,
        mint: mintEN,
        myNfts: myNftsEN,
      },
    },
    lng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
